import type { Tipable } from "@/models/tipable-title-group.ts";
import { currentTheme } from "@/components/shared/theme";

const userMenu = {
    logout: "ログアウト",
    tutorial: "初回チュートリアルを見る",
    manual: "マニュアルをダウンロード",
    upgrade: "アップグレード",
    release_notes: "リリースノート",
    app_version: "バージョン: ",
    current_version: "1.35.0",
};

const gbpConsoleScreen = "Googleビジネスプロフィールの管理画面";

export type Dict = Record<string, string | Tipable>;

export default {
    service: {
        name: "トストア", // ひとまず帳票名はトストアのままとする（直すならサーバー側も変える必要あり）
        fullName: currentTheme().appName, // アプリ名はMEO先に合わせる
    },
    login: {
        notFoundMessage: "メールアドレスまたはパスワードに誤りがあります。",
        emptyMessage: "お客様のメールアドレス・パスワードを入力してください。",
        emptyEmailMessage: "お客様のメールアドレスを入力してください。",
        emptyMFAMessage: "MFAコードを入力してください。",
        emptySetMFAMessage: "設定したMFAのコードを入力してください。",
        duplicateMessage: "2つのパスワード入力欄に同じ文字列を入力してください。",
        emptyVerificationCodeMessage: "セキュリティコードを入力してください。",
        verificationCodeLengthMessage: "6文字のセキュリティコードを入力してください。",
        failedVerificationCodeMessage:
            "セキュリティコードの送信に失敗しました。お手数ですがシステム管理者までご連絡下さい。",
        sentVerificationCodeMessage:
            "セキュリティコード送信を試みました。<br/>" +
            "メールが届かない場合はお手数ですがサポートまでご連絡ください。",
        loginFailedMessage: "ログインに失敗しました。",
        changeFailedMessage: "パスワードの更新に失敗しました。",
        changeSuccessMessage: "パスワードを更新しました。",
        mfaRegistSuccessMessage: "MFAの登録に成功しました。",
        mfaRegistErrorMessage: "MFAの登録に失敗しました。コードが一致しません。",
        mfaRegistUnknownErrorMessage: "MFAの登録に失敗しました。",
        firstChangeSuccessMessage:
            "パスワードを設定しました。<br/>新しいパスワードでログインしてください。",
        attemptsExceededMessage:
            "ログイン試行回数オーバーです。しばらく時間を空けてリトライしてください。",
        userNotAuthorizedMessage:
            "このユーザーはまだ正式登録されていません。ユーザー登録メールに記載された初期パスワードでログインしてください。<br/>" +
            "ユーザー登録メールが届いていない場合はオーナーに正しいメールアドレスでユーザー登録されているか確認してください。",
        codeMismatchMessage:
            "MFAコードが間違っています。送られてきたコードを正しく入力して下さい。",
        codeExpiredMessage:
            "MFAコードが期限切れです。もう一度セキュリティコードをリクエストして下さい。",
        limitExceedMessage:
            "連続して送信できる上限に達しました。しばらく待ってからお試しください。",
        invalidPasswordMessage: "パスワードは6文字以上とし、英字と数字を組み合わせて下さい。",
        invalidMFAMessage: "MFAコードでの認証に失敗しました。",
        verificationFailedMessage:
            "パスワードの更新に失敗しました。お手数ですがシステム管理者までご連絡下さい。",
        timeoutMessage:
            "通信がタイムアウトしました。ネットワークに接続されているか確認してリトライして下さい。",
        errorMessage: "エラーが発生しました。お手数ですがシステム管理者までご連絡ください。",
        sameAsTheFirstMfaMessage:
            "登録時のMFAコードと同一のコードでログインすることはできません。<br>コードが変わってから再度ログインしてください。",
    },
    reports: {
        title: "分析",
        summary: "パフォーマンス",
        reportDownload: "レポートダウンロード",
        review: "クチコミ",
        reviewReport: "クチコミレポート",
        areaReport: "グループインサイト",
        storeReport: "店舗インサイト",
        storeTitle: "管理・運用",
        storeInfoBatch: "基本情報",
        mediaLink: "メディアリンク",
        appleInfo: "Apple Maps",
        storeImageBatch: "画像・動画",
        toolTitle: "ツール",
        gmbpost: "投稿",
        gmbpostHistory: "投稿履歴",
        gmbMedia: "新規登録",
        storeHistories: "店舗情報の変更履歴",
        storeUpdates: "変更提案",
        healthCheck: "ヘルスチェック参照ページ",
        healthCheckLogs: "ヘルスチェック参照ページ > 詳細",
        reportRange: "レポート表示設定",
        reportRanges: [
            ["企業全体", "グループまたは個別店舗"],
            ["担当グループ全体", "グループまたは個別店舗"],
        ],
        reviewAnalysis: "クチコミ",
        reviewReply: "クチコミ返信",
        subTitleStoreInfo: "店舗情報",
        subTitleMediaInfo: "メディア別情報",
        subTitleAction: "発信・アクション",
        foodMenu: "メニュー",
        changePCMode: "PC版に切り替える",
        changeSPMode: "スマホ版に切り替える",
    },
    reportFiles: {
        resultMessageLoading: "データなし",

        name: {
            title: "レポート名",
            supplement: "登録されているレポートファイル名称。",
        },
        status: {
            title: "状態",
            supplement: "レポートファイル生成の処理状況。",
        },
        download: {
            title: "ダウンロード",
            supplement: "レポートファイルをダウンロードします。",
        },
    },
    metric: {
        result_message_loading: "---",
        completeness: {
            title: "店舗情報の完成度",
            supplement: "前日時点での、すべての有効な店舗の店舗情報完成度です。",
            last_update: "最終更新日時",
            address: "住所",
            phone: "電話番号",
            business_hours: "営業時間",
            weekdays: {
                MONDAY: "月",
                TUESDAY: "火",
                WEDNESDAY: "水",
                THURSDAY: "木",
                FRIDAY: "金",
                SATURDAY: "土",
                SUNDAY: "日",
            },
            web_site: "Webサイト",
            business_description: "ビジネス情報",
            store_unit: "店舗",
            view_stores_list: "未記入の店舗一覧へ",
        },
    },
    users: {
        isAllLabel: "無効化されているユーザーも表示する",

        userFormButtonLabel: "ユーザー登録",
        dummyUserFormButtonLabel: "ユーザー登録",

        resultMessageLoading: "データなし",

        familyName: {
            title: "姓",
        },
        firstName: {
            title: "名",
        },
        email: {
            title: "メールアドレス",
        },
        areas: {
            title: "担当グループ",
        },
        stores: {
            title: "担当店舗",
        },
        role: {
            title: "権限",
        },
        mfa: {
            title: "MFA",
        },
        edit: {
            title: "編集",
        },
        deleteButtonName: "削除",
        checkbox_label_isAll: "無効化されているユーザーも表示する",
        desc_deleted: "ユーザーを削除しました。",
        generateErrorMessage_UserNotFoundException_message: "ユーザーが存在しません。",
        generateErrorMessage_exceeds_message: "ユーザー数上限を越えるため有効にできません。",
        generateErrorMessage_delete_unable: "このユーザーを削除する権限がありません。",
        generateErrorMessage_message: "エラーが発生しました。",
        result_message_loading: "登録されたユーザーがいません",
    },
    usersForm: {
        requiredDesc: "印は必須項目です",

        createdDesc: "ユーザーを作成しました。",
        updatedDesc: "ユーザーを更新しました。",

        familyName: {
            title: "ユーザー姓",
            supplement: "登録するユーザーの姓を入力。",
        },
        firstName: {
            title: "ユーザー名",
            supplement: "登録するユーザーの名を入力。",
        },
        mailAddress: {
            title: "メールアドレス",
            supplement: "not defined",
        },
        password: {
            title: "パスワード",
            supplement:
                "ログインパスワードは本人のみが設定・変更可能です。変更する際はログイン画面にて「パスワードを忘れた場合はこちら」をクリックしてください。",
        },
        role: {
            title: "権限",
            supplement: "ユーザー権限を設定。",
        },
        isInCharge: {
            title: "メイン担当者設定",
            supplement:
                "対象企業のメイン担当者であることを示します。設定するとログイン頻度などを確認できるようになります。",
        },
        areaTag: {
            title: "担当グループ",
            supplement:
                "ユーザーの担当グループを設定。担当するグループに含まれる店舗のみ閲覧できます。",
        },
        storeTag: {
            title: "担当店舗",
            supplement: "ユーザーの担当店舗を設定。設定した店舗のみ閲覧できます。",
        },
        areaTagReviewSubscriptions: {
            title: "クチコミアラート通知許可グループ",
            supplement: "通知を許可するグループを設定。",
        },
        storeTagReviewSubscriptions: {
            title: "クチコミアラート通知許可店舗",
            supplement: "通知を許可する店舗を設定。",
        },
        locationUpdateNotification: {
            title: "Google更新通知のON/OFF",
            supplement:
                "Google Business Profile上の店舗情報の変更提案をメールにて通知するかどうかを設定。",
        },
        useMFA: {
            title: "MFA認証を有効化",
            supplement:
                "MFA認証を有効化すると、ログインパスワード入力後にMFAコードの入力が必須になります。\n代わりに、企業全体のIPアドレス制限は受けなくなります。\nOFFに設定しても、MFA登録をリセットするまではMFA認証が必要になります。",
        },
        resetMFA: {
            title: "MFA登録をリセット",
            supplement:
                "無効化を実行すると、登録済みのMFAデバイス情報をクリアしてログイン後にMFAの登録を実行できます。",
            caption: "リセット実行",
        },
        resetMFACautionDialog: {
            title: "注意",
            contents:
                "実行すると、ユーザーが登録したMFA情報がクリアされて、ログイン時に再度MFAの登録が必要です。\nリセット後、再度MFAの登録を行うまでパスワードが漏洩していると第三者にMFAの登録をされてしまいますので、早急にMFAの登録を行ってください",
            submit: "リセット実行",
            cancel: "キャンセル",
            success: "リセットが成功しました",
            failedReset:
                "リセットに失敗しました。お手数ですが、システム管理者にお問い合わせください。",
        },
        postApprove: {
            title: "投稿承認通知",
            supplement:
                "システムで承認者を自動検索している場合の投稿の承認要求の通知をメール配信する設定。\n手動で承認者を設定している場合は設定にかかわらず通知されます。",
        },
        error_validate: "入力に誤りがあります。内容を確認してください。",
        error_user_name_exists: "そのメールアドレスはすでに使用されています。",
        error_exceeds_user_limit: "ユーザー数上限を越えるため作成できません。",
        error_verified: "メールの送信ができませんでした。",
        error_create: "想定外のエラーにより、ユーザーの作成に失敗しました。",
        error_update: "想定外のエラーにより、ユーザーの更新に失敗しました。",
        error_area_cant_find: "一致するグループ名がありません",
        error_no_area_tag: "担当グループは必須項目です。",
        error_limit_area_tag: "担当グループは20を超えて設定できません。",
        error_no_store_tag: "担当店舗は必須項目です。",
        error_limit_store_tag: "担当店舗は20を超えて設定できません。",

        notes_password:
            "ログイン画面の「パスワードを忘れた場合はこちら」から本人が変更してください",

        button_go_back: "戻る",
    },
    areas: {
        result_message_loading: "登録されたグループがありません",
        register_button_label: "グループを登録する",
        resultMessageLoading: "データなし",
        name: {
            title: "グループ名",
            supplement: "企業で管理されているグループの名前です。",
        },
        edit: {
            title: "編集",
            supplement: "グループ情報を編集します。",
        },
        col_delete_button_label: "削除",
    },
    area_form: {
        requiredDesc: "印は必須項目です",
        name: {
            title: "グループ名",
            supplement: "企業で管理されているグループの名前です。",
        },
        col_edit_title: "編集",

        button_go_back: "戻る",
        button_register: "登録する",
        button_update: "更新する",
        button_delete: "削除する",

        error_register: "グループの登録に失敗しました",
        error_update: "グループの更新に失敗しました",
    },
    stores: {
        isAllLabel: "未登録の店舗も表示",
        poiId: {
            title: "POI ID",
            supplement: "設定されている店舗のIDとなります。",
        },
        storeCode: {
            title: "店舗コード",
            supplement: "設定されている店舗の管理用のコードです。",
        },
        poiName: {
            title: "店舗名",
            supplement: "設定されている店舗の名称となります。",
        },
        latitude: {
            title: "緯度",
            supplement: "登録されている店舗の緯度情報となります。",
        },
        longitude: {
            title: "経度",
            supplement: "登録されている店舗の経度情報となります。",
        },
        areaTag: {
            title: "グループ",
            supplement: "店舗の所属グループとなります。",
        },
        foodMenuGroup: {
            title: "メニュー",
            supplement:
                "店舗のメニューとなります。「-(ハイフン)」の店舗は飲食店カテゴリでないのでメニューを設定することはできません",
        },
        alertCondition: {
            title: "クチコミアラート条件",
            supplement: "投稿されたクチコミに関してアラートメールを送信する条件です。",
        },
        rivalName: {
            title: "競合店舗名",
            supplement: "競合店舗の名称となります。表示用なので自由に設定してください。",
        },
        rivalKeyword: {
            title: "検索キーワード",
            supplement:
                "競合店舗をGoogle検索で探すのに使う検索キーワードとなります。確実に見つかる正式名称にすることをおすすめします。",
        },
        edit: {
            title: "編集",
            supplement: "店舗設定を編集します。",
        },

        generateErrorMessage_poiNotFound_message: "店舗が存在しません",
        generateErrorMessage_message: "エラーが発生しました",

        dummyPoiButton_button_label: "店舗を登録する",
        dummyPoiButton_link_button_label: " 店舗を登録する ",

        resultMessageLoading: "データなし",

        assignAreaTag_button_label: "グループを設定する",
        assignFoodMenuGroup_button_label: "メニューを設定する",

        options: {
            overwriteGoogleUpdated: "overwriteGoogleUpdated",
            review: "review",
            post: "post",
            citation: "citation",
            structuredPage: "structuredPage",
        },

        result_message_loading: "設定対象となる店舗がありません",
        noNotification: "通知しない",
    },
    store_form: {
        col_required: "印は必須項目です",

        storeName: {
            title: "店舗名",
            supplement: "店舗の名称となります。",
        },
        rivals: {
            title: "競合店舗",
            supplement: "この自社店舗に対する競合店舗を設定できます。",
        },
        rivalName: {
            title: "競合店舗名",
            supplement: "競合店舗の名称となります。表示用なので自由に設定してください。",
        },
        rivalKeyword: {
            title: "検索キーワード",
            supplement:
                "競合店舗をGoogle検索で探すのに使う検索キーワードとなります。確実に見つかる正式名称にすることをおすすめします。",
        },
        button_go_back: "戻る",
        button_register: "登録する",
        button_update: "更新する",
        button_delete: "削除する",

        error_register: "店舗の登録に失敗しました",
        error_update: "店舗の更新に失敗しました",
    },
    companies: {
        isAllLabel: "全ての店舗が無効の企業も表示する",

        process_register_link_button_label: "企業を登録する",
        resultMessageLoading: "データなし",

        col_poiGroupId_title: "企業ID",
        col_organization_name_title: "企業名",
        col_gmbAccountName_title: "アカウント名",
        col_ownerCount_title: "オーナー数",
        col_userCount_title: "一般ユーザー数",
        col_options_title: "オプション契約",
        col_keywords_title: "対象検索ワード",
        col_scoreType_title: "スコアリング種別",
        col_fedPlatforms_title: "サイテーション対象",
        col_gmbLocationCount_title: "登録店舗数",
        col_edit_title: "編集",
        col_owner_edit_title: "オーナー登録",
        col_company_change_title: "ログイン",
        col_isActive_title: "登録済/未登録",
        col_aggregate_title: "集計実行",

        col_new_owner_button_label: "登録",
        col_change_company_button_label: "ログイン",
        col_aggregate_company_button_label: "実行",
        col_convert_button_label: "変換",
        col_delete_company_button_label: "削除",

        score_type_1: "スコアタイプ1",
        score_type_2: "スコアタイプ2",

        gmbPostEdit: {
            title: "投稿編集",
            supplement: "投稿の編集・削除機能を有効にする企業はこちらからONにしてください。",
        },
        checkbox_label_isAll: "未登録の企業も表示する",
    },
    company_form: {
        col_required: "印は必須項目です",

        col_name_title: "企業名",
        col_auto_block_title: "変更提案を自動で元に戻す(1日に1回)",
        col_auto_block_gmb: "Google Business Profile",
        col_allow_any_aspect_ratio: "Google Business Profile画像・動画の縦横比チェック",
        col_allow_any_aspect_checkbox_label: "常に行わない",
        col_citation_title: "サイテーション設定",
        col_citation_facebook: "Facebook",
        col_citation_instagram: "Instagram",
        col_citation_yplace: "Yahoo!プレイス",
        col_citation_apple: "Apple Business Connect",
        col_citation_review_setting_title: "サイテーション先クチコミ収集",
        col_store_page_hosting: "ホスティング設定",
        col_store_page_hosting_referers: "流入元制限",
        col_store_page_hosting_enabled: "店舗ページホスティングを有効化",
        col_store_page_hosting_s3_path: "公開元のS3 パス(s3://<bucket>/<key>)",
        col_store_page_hosting_public_root_url: "公開URL(https://xxxxxx/)",
        col_store_page_hosting_image_path: "画像公開パス(images/)",
        col_store_page_hosting_cloudfront_distribution_id: "CloudFront DistributionID",
        col_store_page_hosting_cloudfront_distribution_path:
            "CloudFront Distribution Path(CDN cache クリア用パス)",
        col_store_page_hosting_structured_id: "情報統一化用タグの編集",
        col_store_page_hosting_traffic_limit_setting: "ホスティング月間最大配信量",
        col_store_page_hosting_traffic_limit: "ホスティング配信量 / 月間最大配信量",
        col_use_customer_gbp_token: "GBP APIのトークンの種類",
        col_use_customer_gbp_token_false: "g.account を用いる（通常はこちらを選択してください）",
        col_use_customer_gbp_token_true: "オーナー提供のトークンを用いる(OAuth)",
        col_use_customer_gbp_token_true_account_name: "オーナーが設定するので設定不要です",
        col_gmb_account_name_title: "アカウント名",
        col_default_radius: "店舗のデフォルト半径(m)",
        col_ip_whitelist: "接続を許可するIPアドレス(未設定時は全て許可)",
        col_business_type_list: "業種・業態(任意の文字列を複数設定可能)",
        col_options_title: "オプション設定(オーナー・ユーザーの設定)",
        col_options_reviews_disabled: "クチコミ分析無効(チェックで未契約状態)",
        col_keywords_title: "対象検索ワード",
        col_search_keyword_volume_keywords_title: "検索キーワードボリュームの集計キーワード",
        col_placeholder_front: "最大",
        col_placeholder_rear: "個まで追加できます",
        col_post_approve_title: "投稿承認設定",
        col_post_approve_manual_select: "手動で承認者を設定可能",
        col_post_approve_manual_select_supplement:
            "承認を依頼するユーザーが、承認者を一覧から選択可能にします。\n無効の場合は、承認できるユーザーに自動で承認通知メールが送信されます。",
        col_use_opt_gbp_account_title: "RPAアカウント設定",
        col_use_opt_gbp_account_label: "RPAに tostore@opt.ne.jp アカウントを使用する",

        citation_facebook_page: {
            title: "企業と紐付けるページ",
            supplement: "ログイン後にFacebookページ一覧を確認することができます。",
        },
        citation_apple_businesses: {
            title: "Apple Business Connect ブランド",
            supplement: "この企業に紐づける Apple Business Connect のブランド",
        },

        button_go_back: "戻る",
        button_register: "登録する",
        button_update: "更新する",
        button_delete: "削除する",
        button_facebook_login: "Facebookアプリにログイン",
        button_facebook_logout: "Facebookアプリからログアウト",
        button_facebook_deauthorize: "Facebookとの連携を解除",
        button_facebook_deletion: "Facebookに関するデータを全削除",

        check_approve: "内容を確認しました",
        error_register: "企業の登録に失敗しました",
        error_update: "企業の更新に失敗しました",
    },
    adminStores: {
        isAllLabel: "未登録の店舗も表示",

        storeId: {
            title: "店舗ID",
            supplement: "設定されている店舗のIDとなります。",
        },
        storeName: {
            title: "店舗名",
            supplement: "設定されている店舗の名称となります。",
        },
        keywords: {
            title: "検索キーワード",
            supplement: "設定されている店舗の検索キーワードとなります。",
        },
        failed_keyword_count: {
            title: "順位取得失敗キーワード数",
            supplement:
                "企業キーワード x 店舗キーワードでGoogleマップ検索した際に、ヒットしなかったキーワードの数です。",
        },
        latitude: {
            title: "緯度",
            supplement: "登録されている店舗の緯度情報となります。",
        },
        longitude: {
            title: "経度",
            supplement: "登録されている店舗の経度情報となります。",
        },
        overwriteGoogleUpdated: {
            title: "Google更新上書き",
            supplement: "利用する店舗はこちらからONにしてください。",
        },
        review: {
            title: "クチコミ分析(連打禁止！)",
            supplement: "利用する店舗はこちらからONにしてください。",
        },
        post: {
            title: "投稿",
            supplement: "利用する店舗はこちらからONにしてください。",
        },
        citation: {
            title: "サイテーション",
            supplement: "利用する店舗はこちらからONにしてください。",
        },
        structuredPage: {
            title: "店舗ページ構造化",
            supplement: "利用する店舗はこちらからONにしてください。",
        },
        edit: {
            title: "編集",
            supplement: "店舗情報を編集する場合はこちらから編集してください。",
        },
        active: {
            title: "登録済/未登録",
            supplement:
                "利用していない店舗はこちらから無効化してください。（登録可能店舗数は有効化されている店舗が対象となります。）",
        },

        generateErrorMessage_poiNotFound_message: "店舗が存在しません",
        generateErrorMessage_message: "エラーが発生しました",

        dummyPoiButton_button_label: "店舗を登録する",
        dummyPoiButton_link_button_label: " 店舗を登録する ",

        checkbox_label_isAll: "未登録の店舗も表示する",
        resultMessageLoading: "データなし",
    },
    admin_store_form: {
        col_required: "印は必須項目です",

        storeName: {
            title: "店舗名",
            supplement: "店舗の名称となります。",
        },
        storeKeyword: {
            title: "店舗キーワード",
            supplement: "この店舗の検索キーワードとなります。1つのみ設定可能です。",
        },
        rankingResult: {
            title: "検索順位の取得結果",
            supplement:
                "企業キーワード×店舗キーワードでGoogleマップ検索した際の表示順位となります。",
        },
        button_go_back: "戻る",
        button_register: "登録する",
        button_update: "更新する",
        button_delete: "削除する",

        error_register: "店舗の登録に失敗しました",
        error_update: "店舗の更新に失敗しました",
    },
    inquiry_form: {
        col_required: "印は必須項目です",

        title: {
            title: "件名",
            supplement: "お問い合わせの件名になります。",
        },
        type: {
            title: "種別",
            supplement: "お問い合わせの種別になります。",
        },
        message: {
            title: "内容",
            supplement: "お問い合わせの内容になります。",
        },

        inquiryTypes: ["未契約の機能についてのお問い合わせ", "その他のお問い合わせ"],
        button_go_back: "戻る",
        button_register: "送信する",

        error_register: "問い合わせの送信に失敗しました",
    },
    sidebar: {
        process_confButton_label: "設定",
        logout: userMenu.logout,
        tutorial: userMenu.tutorial,
        manual: userMenu.manual,
        upgrade: userMenu.upgrade,
        release_notes: userMenu.release_notes,
        power_health_check: "ヘルスチェック参照",
    },
    topbar: {
        logout: userMenu.logout,
        tutorial: userMenu.tutorial,
        manual: userMenu.manual,
        upgrade: userMenu.upgrade,
        release_notes: userMenu.release_notes,
        app_version: userMenu.app_version,
        current_version: userMenu.current_version,
    },
    request: {
        response_error: "データの取得に失敗しました。",
    },
    storeLocalBusiness: {
        error_no_store: "構造化用の追加情報の取得に失敗しました。",
        error_validate: "入力に誤りがあります。内容を確認してください。",
        same_as_store_info: "GBP店舗情報で設定したものを使用します。",
        updatedSuccessfully: "構造化用の追加情報の更新に成功しました",
        button_go_back: "戻る",
        button_register: "更新する",

        currenciesAccepted: {
            title: "通貨",
            supplement: "例:JPY,USD",
        },
        openingHours: {
            title: "全体の営業時間",
            supplement: "24時間営業の場合:00:00-23:59",
        },
        paymentAccepted: {
            title: "支払い方法",
            supplement: "例:現金, クレジットカード, 暗号通貨",
        },
        priceRange: {
            title: "価格帯",
            supplement: "例:1000-5000",
        },
        areaServed: {
            title: "提供地域",
            supplement: "例:東京",
        },
        brand: {
            title: "ブランド",
            supplement: "",
        },
        brandLogo: {
            title: "ロゴ画像",
            supplement: "画像のURL",
        },
        brandSlogan: {
            title: "スローガン",
            supplement: "例:元気に明るく",
        },
        brandName: {
            title: "ブランド名",
            supplement: "ブランドの名前",
        },
        maximumAttendeeCapacity: {
            title: "収容人数",
            supplement: "例:1000",
        },
        openingHoursSpecification: {
            title: "場所ごとの営業時間",
            supplement: "",
        },
        placeName: {
            title: "場所名",
            supplement: "例:フロア7F",
        },
        opens: {
            title: "営業時間･営業日",
            supplement: "24時間営業の場合:00:00-23:59",
        },
        closes: {
            title: "閉店時間",
            supplement: "例:18:00",
        },
        dayOfWeek: {
            title: "営業日",
            supplement: "例:営業する曜日にチェック",
        },
        smokingAllowed: {
            title: "喫煙有無",
            supplement: "例:true",
        },
        potentialAction: {
            title: "Webから可能なアクション",
            supplement: "例:予約･購入",
        },
        urlTemplate: {
            title: "テンプレートURL",
            supplement: "例:https://www.example.com/reserve?merchantId=20373",
        },
        inLanguage: {
            title: "対応言語",
            supplement: "例:ja-JP",
        },
        actionPlatform: {
            title: "対応プラットフォーム",
            supplement: "例:PC,iOS,Android",
        },
        result: {
            title: "実行結果",
            supplement: "",
        },
        resultName: {
            title: "内容",
            supplement: "例:xxxを予約する",
        },
        category: {
            title: "カテゴリー",
            supplement: "ビジネスの大分類。",
        },
        subcategory: {
            title: "サブカテゴリー",
            supplement: "ビジネスの小分類。",
        },
    },
    storeLocation: {
        notSet: "-",
        editAdditionalButtonLabel: "構造化用の追加情報を編集する",
        editButtonLabel: "店舗情報を編集する",
        noItem: "店舗情報にアクセスできません",
        hasPendingEdits: "審査中",
        disabled: "無効",
        double: "重複",
        ownerConfirmation: "オーナー確認が必要",
        normal: "通常店舗",
        outline: "概要",
        detail: "事業の詳細",
        additionalInfo: "上級ユーザー向けの情報",
        name: {
            title: "ロケーションID",
            supplement: "accounts/{accountId}/locations/{location_id}の形式で固定です。",
        },
        languageCode: {
            title: "言語コード",
            supplement: "ja固定です。",
        },
        locationName: {
            title: "ビジネス名",
            supplement: "店舗のビジネス名です。",
        },
        primaryCategory: {
            title: "メインカテゴリ",
            supplement: "店舗の属するビジネスカテゴリです。",
        },
        additionalCategories: {
            title: "追加カテゴリ",
            supplement: "9個まで設定できる追加カテゴリです。",
        },
        address: {
            title: "住所",
            supplement: "店舗の所在地です。",
        },
        latlng: {
            title: "緯度・経度",
            supplement: "店舗の緯度・経度です。",
        },
        lat: {
            title: "緯度",
            supplement: "店舗の緯度です。",
        },
        lng: {
            title: "経度",
            supplement: "店舗の経度です。",
        },
        regionCode: {
            title: "国コード",
            supplement: "店舗のある国のコードです。",
        },
        serviceArea: {
            title: "サービス提供地域",
            supplement: "店舗がサービスを提供可能な地域です。",
        },
        regularHours: {
            title: "営業時間",
            supplement: "曜日ごとの営業時間です",
        },
        specialHours: {
            title: "特別営業時間",
            supplement: "祝日など特別な日の営業時間です。",
        },
        primaryPhone: {
            title: "電話番号",
            supplement: "携帯電話か固定電話の番号を指定します。",
        },
        additionalPhones: {
            title: "追加の電話番号",
            supplement: "最大2つまで追加の電話番号を指定できます（携帯電話か固定電話のみ）。",
        },
        shortName: {
            title: "短縮名",
            supplement: "店舗情報の短縮URLを作成するときに使う短縮名です。",
        },
        websiteUrl: {
            title: "ウェブサイト",
            supplement: "公式ウェブサイトのURLです。",
        },
        appointmentUrl: {
            title: "面会予約のURL",
            supplement: "問い合わせページのURLです。",
        },
        priceLists: {
            title: "商品",
            supplement: "店舗で扱う商品を登録します。",
        },
        attributes: {
            title: "属性",
            supplement: "店舗の詳細な属性を選択します（項目はカテゴリごとに固定です）。",
        },
        profile: {
            title: "ビジネス情報",
            supplement: "ビジネスの簡単な説明です。",
        },
        openInfo: {
            title: "開業日",
            supplement:
                "店舗が開業した日です。未開業の場合、1年以内の将来の日付を指定することもできます。",
        },
        media: {
            title: "写真・動画",
            supplement: "店舗の写真・動画です。",
        },
        openStatus: {
            title: "開業/閉業",
            supplement: "店舗が開業/閉業しているかです。",
        },
        storeCode: {
            title: "店舗コード",
            supplement: "管理用のコードです。企業ごとにユニークなものにします。",
        },
        labels: {
            title: "ラベル",
            supplement: "管理用のラベルです。好きな単語を10個まで設定可能です。",
        },
        adPhone: {
            title: "Google広告用の電話番号",
            supplement: "Google広告に表示する専用の電話番号がある場合は設定します。",
        },
    },
    storeLocationForm: {
        requiredDesc: "印は必須項目です",
        updatedSuccessfully: "店舗情報の更新に成功しました",
        noDiff: "編集された箇所がありませんでした。",
        button_go_back: "戻る",
        button_register: "更新する",
        error_categories_cant_find: "カテゴリが見つかりません",
        error_validate: "入力に誤りがあります。内容を確認してください。",
        error_update: "想定外のエラーにより、店舗情報の更新に失敗しました。",

        editButtonLabel: "店舗情報を編集する",
        noItem: "店舗情報にアクセスできません",
        outline: "概要",
        detail: "事業の詳細",
        additionalInfo: "上級ユーザー向けの情報",
        name: {
            title: "ロケーションID",
            supplement: "accounts/{accountId}/locations/{location_id}の形式で固定です。",
        },
        languageCode: {
            title: "言語コード",
            supplement: "ja固定です。",
        },
        locationName: {
            title: "ビジネス名",
            supplement: "店舗のビジネス名です。",
        },
        primaryCategory: {
            title: "メインカテゴリ",
            supplement: "店舗の属するビジネスカテゴリです。",
        },
        additionalCategories: {
            title: "追加カテゴリ",
            supplement: "9個まで設定できる追加カテゴリです。",
        },
        address: {
            title: "住所",
            supplement: "店舗の所在地です。",
        },
        postalCode: {
            title: "郵便番号",
            supplement: "店舗の郵便番号です。",
        },
        administrativeArea: {
            title: "都道府県",
            supplement: "店舗の所在する都道府県です。",
        },
        addressLines: {
            title: "住所",
            supplement: "店舗の所在地です。必須です。",
        },
        latlng: {
            title: "緯度・経度",
            supplement: "店舗の緯度・経度です。",
        },
        lat: {
            title: "緯度",
            supplement: "店舗の緯度です。",
        },
        lng: {
            title: "経度",
            supplement: "店舗の経度です。",
        },
        regionCode: {
            title: "国コード",
            supplement: "店舗のある国のコードです。",
        },
        serviceArea: {
            title: "サービス提供地域",
            supplement: "店舗がサービスを提供可能な地域です。",
        },
        regularHours: {
            title: "営業時間",
            supplement: "曜日ごとの営業時間です",
        },
        specialHours: {
            title: "特別営業時間",
            supplement: "祝日など特別な日の営業時間です。",
        },
        primaryPhone: {
            title: "電話番号",
            supplement: "携帯電話か固定電話の番号を指定します。最低ひとつは必須です。",
        },
        additionalPhones: {
            title: "追加の電話番号",
            supplement: "最大2つまで追加の電話番号を指定できます（携帯電話か固定電話のみ）。",
        },
        shortName: {
            title: "短縮名",
            supplement: "店舗情報の短縮URLを作成するときに使う短縮名です。",
        },
        websiteUrl: {
            title: "ウェブサイト",
            supplement: "公式ウェブサイトのURLです。",
        },
        appointmentUrl: {
            title: "面会予約のURL",
            supplement: "問い合わせページのURLです。",
        },
        priceLists: {
            title: "商品",
            supplement: "店舗で扱う商品を登録します。",
        },
        attributes: {
            title: "属性",
            supplement: "店舗の特徴を選択します（項目はカテゴリごとに固定です）。",
        },
        profile: {
            title: "ビジネス情報",
            supplement: "ビジネスの簡単な説明です。",
        },
        openInfo: {
            title: "開業日",
            supplement:
                "店舗が開業した日です。未開業の場合、1年以内の将来の日付を指定することもできます。",
        },
        media: {
            title: "写真",
            supplement: "店舗の写真です。",
        },
        openStatus: {
            title: "開業/閉業",
            supplement:
                "店舗の開業状態を設定します。開業、完全な閉業、一時的な休業、不明な状態を設定できます。",
        },
        storeCode: {
            title: "店舗コード",
            supplement: "管理用のコードです。企業ごとにユニークなものにします。",
        },
        labels: {
            title: "ラベル",
            supplement: "管理用のラベルです。好きな単語を10個まで設定可能です。",
        },
        adPhone: {
            title: "Google広告用の電話番号",
            supplement: "Google広告に表示する専用の電話番号がある場合は設定します。",
        },
        weekdays: {
            MONDAY: "月",
            TUESDAY: "火",
            WEDNESDAY: "水",
            THURSDAY: "木",
            FRIDAY: "金",
            SATURDAY: "土",
            SUNDAY: "日",
        },
        mediaCategories: [
            // Format: JPG or PNG.
            // Size: Between 10 KB and 5 MB.
            // Minimum resolution: 250 x 250 px
            {
                name: "PROFILE",
                title: "ロゴ",
                supplement: "企業のロゴ画像",
            },
            {
                name: "COVER",
                title: "カバー",
                supplement: "企業のカバー画像",
            },
            {
                name: "EXTERIOR",
                title: "外観",
                supplement: "店舗の外観の写真",
            },
            {
                name: "INTERIOR",
                title: "店内",
                supplement: "店舗内の写真",
            },
            {
                name: "TEAMS",
                title: "チーム",
                supplement: "チームの写真",
            },
            {
                name: "CATEGORY_UNSPECIFIED",
                title: "動画",
                supplement: "動画のみの情報",
            },
            {
                name: "TEAMS",
                title: "チーム",
                supplement: "チームの写真",
            },
        ],
        openStatuses: [
            { name: "開業", value: "OPEN" },
            { name: "完全閉業", value: "CLOSED_PERMANENTLY" },
            { name: "臨時休業", value: "CLOSED_TEMPORARILY" },
            { name: "不明", value: "OPEN_FOR_BUSINESS_UNSPECIFIED" },
        ],
    },
    v2post: {
        histories: {
            status: {
                INCOMPLETE: "一時保存(新規)",
                READY: "投稿予約中",
                EDIT: "編集予約中",
                DELETE: "削除予約中",
                INPROGRESS: "処理中",
                EDITINPROGRESS: "編集処理中",
                DELETEINPROGRESS: "削除処理中",
                FINISHED: "投稿完了",
                EDITFINISHED: "編集完了",
                DELETEFINISHED: "削除完了",
                FAILED: "投稿失敗",
                EDITFAILED: "編集失敗",
                DELETEFAILED: "削除失敗",
                AUTODELETED: "自動削除済み",
                EDITINCOMPLETE: "一時保存(編集)",
                JUDGEMENTREQUEST: "承認要求",
                JUDGEMENTREJECT: "承認却下",
                NOTUSE: "-",
                UNKNOWN: "不明",
            },
            gbpType: {
                info: "最新情報",
                event: "イベント",
                benefits: "特典",
                product: "商品",
                covid19: "COVID-19",
                default: "-",
            },
        },
        validator: {
            invalidExtension: "投稿可能な拡張子ではありません",
            // TODO: すべてエラーメッセージをメディアごとに分けたい
            invalidExtensionGBP: "Googleは png, jpg, mov, mp4 のみ対応です",
            invalidExtensionYahoo: "Yahooは png, jpg のみ対応です",
            invalidExtensionIG: "Instagramは jpg, mov, mp4 のみ対応です",
            invalidExtensionFB: "Facebookは png, jpg, mov, mp4 のみ対応です",
            invalidExtensionHosting: "ホスティングは png, jpg, mov, mp4 のみ対応です",
            rejectMovie: "動画投稿はできません",
            fileSizeIsSmall: "ファイルサイズが小さすぎます",
            fileSizeIsBig: "ファイルサイズが大きすぎます",
            secondsIsShort: "再生時間が短すぎます",
            secondsIsLong: "再生時間が長すぎます",
            widthIsSmall: "横幅が短すぎます",
            widthIsBig: "横幅が長すぎます",
            heightIsSmall: "縦幅が短すぎます",
            heightIsBig: "縦幅が長すぎます",
            invalidAspectRatio: "縦横比が正しくありません",
        },
        caution: {
            publicationDelayWarning:
                "投稿内容によってはメディア側の審査により掲載までお時間がかかる場合がございます。",
            gbpProduct:
                "Google Business Profile の仕様により、商品投稿および削除が不安定になる場合がございます。ご了承ください。",
            instagram: `Instagram のシステムの仕様上、投稿の編集削除を${
                currentTheme().appName
            }上から行えません。`,
            facebook:
                "Facebook のシステムの仕様上、写真・動画の差し替えができません。変更がある場合は現在掲載されている投稿を削除し、新たに投稿を作成します。",
            reviewRequest: "承認者に承認されてから、実際の投稿や予約が実施されます。",
            postApproval: "承認すると予約開始日時が設定されていない場合は即時投稿が実行されます。",
            brokenVideo:
                "破損している可能性のある動画ファイルが含まれています。Google Business Profileの場合Google側で自動的に変換等が行われて再生が可能になることがありますが、このまま続行しても良いかご検討ください。",
        },
    },
    gbpConsoleScreen,
    companyReviews: {
        reportName: "企業全体クチコミ",
    },
    healthCheck: {
        reportName: "ヘルスチェック",
    },
    storesHistories: {
        name: "店舗名",
        createdAt: "更新日時",
        key: "変更項目",
        before: "変更前",
        after: "変更後",
    },
    storeUpdates: {
        needsManualUpdate: {
            title: "手動更新が必要な店舗を表示",
            supplement:
                "GBP管理画面で手動更新が必要な店舗を表示します。手動更新が必要な店舗はチェックが付けられません。対象店舗のステータスのツールチップから手順を確認して下さい。",
        },
        needsWaiting: {
            title: "反映待ちの店舗を表示",
            supplement: "承認・元に戻すを選択済みで、反映待ちになっている店舗を表示します。",
        },
        name: {
            title: "店舗名",
            supplement: "Google変更提案があった店舗名です。",
        },
        updateTime: {
            title: "更新日",
            supplement: "Google変更提案があった日付けです。",
        },
        status: {
            title: "ステータス",
            supplement:
                "変更提案の状態です。元に戻す・承認を選択することで「元に戻し中」「承認中」になります。GBPへの更新リクエストが実行され、反映を待つ状態が「GBP反映待ち」です。",
        },
        caption: {
            title: "項目",
            supplement: "変更提案のあった項目名です。",
        },
        source: {
            title: "変更前",
            supplement: "変更前の内容です。",
        },
        changes: {
            title: "変更後",
            supplement: "変更後の内容です。",
        },
        result_message_loading:
            "選択されたグループにはGoogle変更提案を受けた未処理の店舗はありません",
        stateNames: {
            0: "変更なし",
            1: "変更提案あり",
            10: "元に戻し中",
            11: "承認中",
            2: "GBP反映待ち",
            3: "更新不可",
            30: "手動更新が必要",
        },
        same_change_request_state: "前回と同じ変更提案あり",
        same_change_request_message:
            "【元に戻す】を実行しましたが同じ変更提案をされました。Googleはこの項目を設定必須だと考えている可能性があります。店舗情報の一括更新画面にて提案項目を設定することをご検討下さい。",
    },
    storeKeywords: {
        title: "キーワード設定",
        button_go_back: "戻る",
        button_extract: "キーワード抽出",
        button_submit: "キーワードを設定して次へ",
        brandNames: {
            title: "ブランド名",
            supplement:
                "店舗名に含まれるブランド名を記入してください。ブランドが複数ある場合はカンマ区切りで入力してください。",
        },
        noResults: "キーワード未設定の店舗が見つかりませんでした",
    },
    citationSettingsYahoo: {
        name: "Yahoo!プレイス連携設定",
    },
    citationSettingsApple: {
        name: "AppleBusinessConnect連携設定",
    },
};

// ユーザ権限によって共通文言を差し替えるための構造体
export type PostStoresSelectDict = {
    Step2Name: string;
    Step2Title: string;
    Step2Description: string;
    BTitle: string;
};

export function postStoresSelectDict(canShowAllowedStoresOnly: boolean): PostStoresSelectDict {
    if (canShowAllowedStoresOnly) {
        return {
            Step2Name: "投稿する店舗の選択",
            Step2Title: "投稿する店舗の選択",
            Step2Description:
                "全ての店舗を対象として一括投稿するか、もしくは投稿対象にしたい店舗を個別にお選びいただけます。",
            BTitle: "個別の店舗を選択して投稿",
        };
    }
    return {
        Step2Name: "投稿するグループ／店舗の選択",
        Step2Title: "投稿するグループ/店舗の選択",
        Step2Description:
            "全ての店舗を対象として一括投稿するか、もしくは投稿対象にしたいグループや店舗を個別にお選びいただけます。",
        BTitle: "グループや個別の店舗を選択して投稿",
    };
}
